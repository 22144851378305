<template>
  <div class="page row">
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="d-flex mb-3">
            <b-form-group
              label="Start date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.start"
                type="datetime"
                :auto="true"
                @input="loadLog"
              ></datetime>
            </b-form-group>
            <b-form-group
              label="End date"
              class="date-call-log select col-3 align-center"
            >
              <datetime
                v-model="date.end"
                type="datetime"
                :auto="true"
                @input="validEndDate(), loadLog()"
              ></datetime>
            </b-form-group>
          </div>

          <div class="table-responsive" v-if="!$store.state.loading">
            <div class="col-12 pl-0" v-if="!items || items.length == 0">
              No Data Found
            </div>
            <!-- v-else -->
            <table class="table center-aligned-table" v-else>
              <thead>
                <tr>
                  <th width="33%">Date</th>
                  <th width="33%" class="text-center">Top-Up</th>
                  <th width="33%" class="text-center">Usage</th>
                </tr>
              </thead>
              <tbody v-if="items">
                <tr v-for="item in items" :key="item.contact_id">
                  <td>{{ getDate(item.timestamp) }}</td>
                  <td class="text-center">
                    {{
                      checkType("top-up", item)
                    }}
                  </td>
                  <td class="text-center">
                    {{ checkType('usage', item) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { mapGetters } from "vuex";

export default {
  components: {
    Datetime,
  },
  data() {
    return {
      date: {
        end: null,
        start: null,
      },
      selected: "All",
      items: null,
      phone: null,
      key: null,
      completedSort: false,
      dnisSort: false,
      load: false,
    };
  },
  async mounted() {
    this.$store.commit("updateLoading", true);
    if(this.$route.query.payment) {
      this.$swal.fire("Payment success!", "", "success");
    }
    this.initDate();
    this.load = true;
    await this.$store.dispatch("loadItems");
    const params = await this.$route.params.camp;
    if (params) {
      this.selected = params.trim();
    }
    this.loadLog();
  },
  computed: {
    ...mapGetters(["admin", "loading"]),
    campaigns() {
      let items = [
        {
          value: "All",
          text: "All",
        },
      ];
      this.items.forEach((el) => {
        let obj = {
          value: el.id,
          text: el.name,
        };
        items.push(obj);
      });
      return items;
    },
  },
  methods: {
    initDate() {
      let today = new Date();
      const last_date = today.setHours(23, 59, 0, 0);
      const first_date = today.setDate(today.getDate() - 31);
      this.date.start = this.$moment(first_date).toISOString();
      this.date.end = this.$moment(last_date).toISOString();
    },
    checkType(type, item) {
      const start = item.balance_start
      const end = item.balance_end
      const value = item.amount
      if(type == 'top-up') {
        if(start < end) return value
        else return '-'
      } else {
        if(start >= end) return value
        else return '-'
      }
    },
    loadLog() {
      if (this.load) {
        this.$store.commit("updateLoading", true);

        let payload = {};
        if (this.date.start) {
          payload["period"] = [this.toUnix(this.date.start)];
          let end = this.date.end
            ? this.toUnix(this.date.end)
            : this.toUnix(this.$moment());
          payload.period.push(end);
        }
        this.$axios
          .post(`${this.admin}/transactions/query`, payload)
          .then((res) => {
            console.log(res.data);
            this.items = res.data.transactions;
            this.$store.commit("updateLoading", false);
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("updateLoading", false);
          });
      }
    },
    toUnix(date) {
      return this.$moment(date).unix();
    },
    getDate(date) {
      return this.$moment.unix(date).format("MMMM Do YYYY, hh:mm");
    },
    validEndDate() {
      if (this.date.end < this.date.start) {
        this.date.end = this.date.start;
      }
    },
  },
};
</script>
<style scoped>
.custom-select {
  font-size: 13px;
}
.form-control-sm {
  height: 2rem;
}
.custom-select {
  max-height: 32px;
}
</style>
<style lang="scss">
.form-group.date-call-log {
  margin-bottom: 0;
  padding-left: 0;
  .col-form-label {
    padding-bottom: 0.5rem;
    line-height: normal;
    vertical-align: middle;
    font-size: 0.813rem;
    line-height: 1;
    vertical-align: top;
  }
  input {
    border: 1px solid #f6f2f2;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    line-height: 14px;
    font-weight: 300;
    height: 2rem;
    width: 100%;
  }
}
</style>